// MAIN.JS
jQuery(function($) {

	/**********************************************/
	// JQUERY.APPEAR
	if ($.fn.appear) {
		var appearSelectors = ".appear";
		$(appearSelectors).on('appear', function(event, $all_appeared_elements) {
			$.each($all_appeared_elements, function(e) {
				$(this).css("opacity", 1);
			});
		});
		$(appearSelectors).on('disappear', function(event, $all_disappeared_elements) {
			$.each($all_disappeared_elements, function(e) {
				// Activer ceci pour faire l'inverse lors que l'element sort de la partie visible
				// $(this).css("opacity", 0);
			});
		});
		$(window).load(function() {
			$(appearSelectors).appear({"force_process":true});
		});
	}

	/**********************************************/

	// JQUERY.MASKEDINPUT
    if ($.fn.mask) {
        $("input[type='tel']").mask("(999) 999-9999? #99999");
        $("input[type='text'].postalcode").mask("a9a 9a9", {
            completed:function () {
                this.val(this.val().toUpperCase());
            }
        });
    }

	/**********************************************/
	/**** HORIZONTAL IMAGE ACCORDION *****/

	  var x = $('.x');
	  var accordion = $('.accordion');

	  var item = $('.accordion__item');
	  var itemLength = item.length;
	  var itemWidth = accordion.width() / itemLength;

	  var active = $('.accordion .active');
	  var activeSiblings = 3;//il faut metre le numbre de items inactives//active.siblings().length;

	  var activeWidth = (accordion.width() / 100) * (100 - 15 * itemLength);
	  var notActiveWidth = ( accordion.width() - activeWidth ) / ( activeSiblings );

	  item.width(itemWidth);
	  item.hover(function(){
	    $(this)
	      .addClass('active')
	      .stop(true)
	      .animate({'width': activeWidth + 'px'})
	      .siblings()
	      .removeClass('active')
	      .stop(true)
	      .animate({'width': notActiveWidth + 'px'});
	    },function(){
	       item.removeClass('active')
	      .stop(true)
	      .animate({'width': itemWidth + 'px'});
	  });


/**********************************************/

	$slideshow = $('.slideshow_accordion');
	var initSlideshow = function() {
		return setInterval(function() {
			$slides = $slideshow.find('.slide');
			$active = $slideshow.find('.slide.active');
			$next = $active.next();

			if ($next.length > 0) {
				$active.removeClass('active');
				$active.addClass('transiting');
				$active.next().addClass('active');
			}
			else {
				$active.removeClass('active');
				$active.addClass('transiting');
				$slides.first().addClass('active');
			}
			setTimeout(function() {
				$active.removeClass('transiting');
			}, 1500);
		}, 3000);
	}

	$(document).ready(function() {
		var slideshowTimer = initSlideshow();
	});


	/**********************************************/
	/**********************************************/

	// MENU
	var menu = ".menu-mobile";
	var menuToggle = ".menu-toggle";
	var menuToggleEn = ".menu-toggle-en";
	$(menuToggle).click(function(e) {

		if ($(".menu-toggle").hasClass("toggled")) {
			$('.menu-toggle > .word').html('menu');
		}else{
			$('.menu-toggle > .word').html('retour');
		}

		$(menu).toggleClass("toggled");
		$(menuToggle).toggleClass("toggled");
	});

	$(menuToggleEn).click(function(e) {

		if ($(".menu-toggle-en").hasClass("toggled")) {
			$('.menu-toggle-en').html('menu');
		}else{
			$('.menu-toggle-en').html('back');
		}

		$(menu).toggleClass("toggled");
		$(menuToggleEn).toggleClass("toggled");
	});
	/**********************************************/

	// JQUERY.MATCHHEIGHT
	if ($.fn.matchHeight) {
		$(".matchHeight").matchHeight();
	}


	/************************************************************************/
	/* menu hide and show when scrolled	and other scroll functions			*/
	/************************************************************************/
    let didScroll;
    let lastScrollTop = 0;
    const delta = 10;
    let navbarHeight = $(".menu-navbar").outerHeight();
    // function to store scroll amount in <html data-scroll="">
	const storeScroll = () => {
        document.documentElement.dataset.scroll = window.scrollY;
    }

    // Set flag to see if window has scrolled
	$(window).scroll( function(event) {
		didScroll = true;
        storeScroll();
	});

    // Calls all scroll dependent scripts
    // And cut on processing time by the browser
    // by reading stats only x milliseconds (250)
	setInterval(function() {
		if (didScroll) {
            menuHasScrolled();
            toggleVisibleClassOnScroll();
            didScroll = false;
		}
	}, 250);

	// if element has "visibilityCheck" it will have "isVisible" class when
    // visible
	function toggleVisibleClassOnScroll() {
        $('.visibilityCheck').is( function () {
            let viewing = $(this).visible(true);
            if (viewing) {
                $(this).addClass('isVisible');
            } else {
                //$(this).removeClass('isVisible');
            }
        });
        $('.completeVisibilityCheck').is( function () {
            let viewing = $(this).visible();
            if (viewing) {
                $(this).addClass('isCompletelyVisible');
            } else {
                $(this).removeClass('isCompletelyVisible');
            }
        });
    }
    

    // Adds the nav-invisible and nav-visible to element with class menu-desktop
	function menuHasScrolled() {
        let st = parseInt(document.documentElement.dataset.scroll);

        // Make sure they scroll more than delta
	    if ( Math.abs(lastScrollTop - st) <= delta )
	        return;
	    // If they scrolled down and are past the navbar, add class .nav-invisible.
	    // This is necessary so you never see what is "behind" the navbar.
        //console.log('st: ' + st);
        //console.log('lastScrollTop: ' + lastScrollTop);

        if ( st < parseInt(lastScrollTop) ) {
            // Scroll Up
            $('.menu-navbar').addClass('nav-visible').removeClass('nav-invisible');
        }
	    if (st > lastScrollTop && st > navbarHeight) {
	        // Scroll Down
	        $('.menu-navbar').removeClass('nav-visible').addClass('nav-invisible');
	    }

		lastScrollTop = st;

		if (st > 0) {
			$('.module-menu').addClass('scrolled');
		}
		else {
			$('.module-menu').removeClass('scrolled');
		}
	}

	// write the scroll position on page load
    $(document).ready(function () {
        storeScroll();
        toggleVisibleClassOnScroll();//ajout ce ligne la
    });


	/************************************************/
	/* Ferme le popup si cliquer	 				*/
	/************************************************/
	$(".popup").click(function(e) {
	    $(".popup").fadeOut();
	});


		$('a.scroll-link').click(function(e){
			//e.preventDefault();
				var url = $(this).attr('href');
				var id = url.substring(url.lastIndexOf('#') + 1);
				if ($('#' + id).length) {
						$('body,html').animate({
								scrollTop: $('#' + id).offset().top - 120
						}, 750);
				}
	});


	/************************************************/
	/* Montre les messages si présents 				*/
	/************************************************/
	$(document).ready(function () {
        if($('.errors').length) {
            $('html, body').animate({
                scrollTop: $('.errors').offset().top + 200
            }, 'slow');
        }
    });


    $(document).ready(function () {
		if($('.messages').length) {
			$('html, body').animate({
				scrollTop: $('.messages').offset().top + 200
			}, 'slow');
		}
    });

    $('.caption').addClass ('show');
});
